import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import { getUsersByBusiness } from './usersApi';
import useOutsideClick from './Hooks';

const EditCardSidebar = ({ isOpen, onClose, onSave, businessId, pipelineId, editingCard }) => {
  const [cardData, setCardData] = useState({
    name: '',
    description: '',
    contact: null,
    stage: null,
    users_permissions_user: null,
    business: businessId,
    pipeline: pipelineId
  });

  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [contactSearch, setContactSearch] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [products, setProducts] = useState([]);
const [filteredProducts, setFilteredProducts] = useState([]);
const [productSearch, setProductSearch] = useState('');
const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);


  const [stages, setStages] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);
  const [stageSearch, setStageSearch] = useState('');
  const [isStageDropdownOpen, setIsStageDropdownOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllCardData = async () => {
  setIsLoading(true);
  setError(null);
  try {
    const [contactsResponse, usersResponse, stagesResponse, productsResponse] = await Promise.all([
      axios.get(`${config.API_URL}/api/contacts?filters[business][id][$eq]=${businessId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      }),
      getUsersByBusiness(businessId),
      axios.get(`${config.API_URL}/api/pipelines/${pipelineId}?populate[stages][fields][0]=name`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      }),
      axios.get(`${config.API_URL}/api/products?filters[business][id][$eq]=${businessId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      })
    ]);

    console.log('Editing Card Data:', editingCard);
    console.log('Product Data in Card:', editingCard?.attributes?.product);
    console.log('Contact Data in Card:', editingCard?.attributes?.contact);

    setContacts(contactsResponse.data.data);
    setUsers(usersResponse);
    setStages(stagesResponse.data.data.attributes.stages.data);
    setProducts(productsResponse.data.data);

    if (editingCard) {
      setCardData({
        name: editingCard.attributes.name,
        description: editingCard.attributes.description,
        contact: editingCard.attributes.contact?.data,
        stage: editingCard.attributes.stage?.data?.id,
        users_permissions_user: editingCard.attributes.users_permissions_user?.data?.id,
        product: editingCard.attributes.product?.data,
        business: businessId,
        pipeline: pipelineId
      });
      
      setContactSearch(editingCard.attributes.contact?.data?.attributes?.name || '');
      setStageSearch(editingCard.attributes.stage?.data?.attributes?.name || '');
      setUserSearch(editingCard.attributes.users_permissions_user?.data?.attributes?.username || '');
      setProductSearch(editingCard.attributes.product?.data?.attributes?.name || '');
    }

    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching card data:', error);
    setError('Failed to load card data. Please try again.');
    setIsLoading(false);
  }
};

  useEffect(() => {
    if (isOpen) {
      fetchAllCardData();
    }
  }, [isOpen, businessId, pipelineId, editingCard]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleContactSelect = (contact) => {
    setCardData(prevData => ({
      ...prevData,
      contact: { id: contact.id, name: contact.attributes.name }
    }));
    setContactSearch(contact.attributes.name);
    setIsDropdownOpen(false);
  };

  const handleStageSelect = (stage) => {
    setCardData(prevData => ({ ...prevData, stage: stage.id }));
    setStageSearch(stage.attributes.name);
    setIsStageDropdownOpen(false);
  };

  const handleUserSelect = (user) => {
    setCardData(prevData => ({ ...prevData, users_permissions_user: user.id }));
    setUserSearch(user.username);
    setIsUserDropdownOpen(false);
  };

  const handleContactSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setContactSearch(searchTerm);
    setFilteredContacts(contacts.filter(contact => 
      contact.attributes.name.toLowerCase().includes(searchTerm) ||
      contact.attributes.email.toLowerCase().includes(searchTerm)
    ));
  };

  const handleStageSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setStageSearch(searchTerm);
    setFilteredStages(stages.filter(stage => 
      stage.attributes.name.toLowerCase().includes(searchTerm)
    ));
    setIsStageDropdownOpen(true);
  };

  const handleUserSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setUserSearch(searchTerm);
    setFilteredUsers(users.filter(user => 
      user.username.toLowerCase().includes(searchTerm)
    ));
    setIsUserDropdownOpen(true);
  };

  const handleProductSearch = (e) => {
  const searchTerm = e.target.value.toLowerCase();
  setProductSearch(searchTerm);
  setFilteredProducts(products.filter(product => 
    product.attributes.name.toLowerCase().includes(searchTerm) ||
    product.attributes.price.toString().includes(searchTerm)
  ));
  setIsProductDropdownOpen(true);
};

const handleProductSelect = (product) => {
  setCardData(prevData => ({
    ...prevData,
    product: { id: product.id, name: product.attributes.name }
  }));
  setProductSearch(product.attributes.name);
  setIsProductDropdownOpen(false);
};

//   const handleSave = () => {
//     onSave(cardData);
//     onClose();
//   };

const handleSave = () => {
    if (!isLoading) {
      onSave(cardData);
    }
  };



  const dropdownRef = useOutsideClick(() => {
    setIsDropdownOpen(false);
    setIsStageDropdownOpen(false);
    setIsUserDropdownOpen(false);
  });



  if (isLoading) {
    return (
      <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl flex items-center justify-center transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }
  

  if (error) return <div>{error}</div>;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-2xl font-bold mb-4">Edit Card</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={cardData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={cardData.description}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                rows="3"
              ></textarea>
            </div>

            <div ref={dropdownRef}>
              <label className="block text-sm font-medium text-gray-700">Contact</label>
              <div className="relative mt-1">
                <input
                  type="text"
                  value={contactSearch}
                  onChange={handleContactSearch}
                  onClick={() => setIsDropdownOpen(true)}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  placeholder="Search contacts..."
                />
                {isDropdownOpen && (
                  <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {filteredContacts.map(contact => (
                      <li
                        key={contact.id}
                        onClick={() => handleContactSelect(contact)}
                        className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                      >
                        {contact.attributes.name} ({contact.attributes.email})
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div>
  <label className="block text-sm font-medium text-gray-700">Product</label>
  <div className="relative mt-1">
    <input
      type="text"
      value={productSearch}
      onChange={handleProductSearch}
      onClick={() => setIsProductDropdownOpen(true)}
      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
      placeholder="Search products..."
    />
    {isProductDropdownOpen && (
      <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg">
        {filteredProducts.map(product => (
          <li
            key={product.id}
            onClick={() => handleProductSelect(product)}
            className="cursor-pointer py-2 px-3 hover:bg-gray-100"
          >
            {product.attributes.name} - {product.attributes.price}
          </li>
        ))}
      </ul>
    )}
  </div>
</div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Stage</label>
              <div className="relative mt-1">
                <input
                  type="text"
                  value={stageSearch}
                  onChange={handleStageSearch}
                  onClick={() => setIsStageDropdownOpen(true)}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  placeholder="Search stages..."
                />
                {isStageDropdownOpen && (
                  <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {filteredStages.map(stage => (
                      <li
                        key={stage.id}
                        onClick={() => handleStageSelect(stage)}
                        className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                      >
                        {stage.attributes.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Assign User</label>
              <div className="relative mt-1">
                <input
                  type="text"
                  value={userSearch}
                  onChange={handleUserSearch}
                  onClick={() => setIsUserDropdownOpen(true)}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  placeholder="Search users..."
                />
                {isUserDropdownOpen && (
                  <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {filteredUsers.map(user => (
                      <li
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                      >
                        {user.username}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
      onClick={handleSave} 
      className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
      disabled={isLoading}
    >
      {isLoading ? 'Saving...' : 'Save'}
    </button>
        </div>
      </div>
    </div>
  );
};

export default EditCardSidebar;
