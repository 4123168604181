import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './config/config';
import { BusinessProvider, useBusinessContext } from './context/BusinessContext';

// Component imports

import ProtectedRoute from './components/ProtectedRoute';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AddContact from './components/AddContact';
import Registration from './components/Registration';
import Contacts from './components/Contacts';
import Products from './components/Products';
import Pipelines from './components/Pipelines';
import PipelineView from './components/PipelineView';
import Login from './components/Login';
import CardView from './components/CardView';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Accounts from './components/Accounts';
import StaffLogin from './components/StaffLogin';
import OwnerLogin from './components/OwnerLogin';

import AgencyLogin from './components/agency/AgencyLogin';
import BusinessAccounts from './components/agency/BusinessAccounts';
import AgencyPlans from './components/agency/AgencyPlans';
import BusinessUsers from './components/agency/BusinessUsers';
import AgencyDashboard from './components/agency/AgencyDashboard';

import AdminLogin from './components/admin/AdminLogin';
import AdminSettings from './components/admin/AdminSettings';
import AgencyAccounts from './components/admin/AgencyAccounts';
import AgencyUsers from './components/admin/AgencyUsers';
import AgencyBusinesses from './components/admin/AgencyBusinesses';
import AgencyBusinessUsers from './components/admin/AgencyBusinessUsers';
import AdminPlans from './components/admin/AdminPlans';
import AdminDashboard from './components/admin/AdminDashboard';


const AppContent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const { businessId, userData, userRoles, setBusinessContext } = useBusinessContext();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('jwt');
      console.log('App: Checking authentication, token:', token ? 'exists' : 'not found');
      if (token) {
        try {
          const response = await axios.get(`${config.API_URL}/api/users/me`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          console.log('App: Authentication successful, user data:', response.data);

          // First get the user's role and related IDs
            const roleResponse = await axios.get(`${config.API_URL}/api/users/${response.data.id}?populate=role,business,agency`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            
            console.log('User role data:', roleResponse.data);



          if (response.data) {

            console.log('Basic user data:', response.data);
  console.log('Role response data:', roleResponse.data);
  console.log('Role details:', roleResponse.data.role);
  console.log('Business details:', roleResponse.data.business);
  console.log('Agency details:', roleResponse.data.agency);


            setIsAuthenticated(true);
            const storedBusinessId = localStorage.getItem('businessId');
            const storedUserData = JSON.parse(localStorage.getItem('userData') || 'null');
            const storedUserRoles = JSON.parse(localStorage.getItem('userRoles') || 'null');
            setBusinessContext({
              businessId: storedBusinessId ? parseInt(storedBusinessId, 10) : null,
              userData: storedUserData,
              userRoles: storedUserRoles
            });
          }
        } catch (error) {
          console.error('App: Authentication error:', error);
          localStorage.removeItem('jwt');
          localStorage.removeItem('userData');
          localStorage.removeItem('userRoles');
        }
      }
      setLoading(false);
    };
    
    checkAuth();
  }, []);

  useEffect(() => {
    console.log('App: Current context:', { businessId, userData, userRoles });
  }, [businessId, userData, userRoles]);

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
    console.log('App: Auth state updated:', boolean);
  };

  const logout = () => {
    console.log('App: Logging out');
    
    // Store the user role before clearing localStorage
    const userRole = userRoles?.type;
    
    // Clear all storage and reset states
    localStorage.removeItem('jwt');
    localStorage.removeItem('businessId');
    localStorage.removeItem('userData');
    localStorage.removeItem('userRoles');
    setIsAuthenticated(false);
    setBusinessContext({
      businessId: null,
      userData: null,
      userRoles: null
    });

    // Role-based redirects
    switch (userRole) {
      case 'staff':
        navigate('/staff/login');
        break;
      case 'owner':
        navigate('/owner/login');
        break;
      case 'agency':
        navigate('/agency/login');
        break;
      case 'admin':
        navigate('/admin/login');
        break;
      default:
        navigate('/login');
    }
};




  if (loading) {
    return <div>Loading App...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {isAuthenticated && <Header logout={logout}/>}
      <div className="flex flex-1">
        <main className={`flex-1 bg-white overflow-x-auto`}>
          <Routes>
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} allowedRoles={['owner', 'staff']} />} />
            <Route path="/pipelines" element={<ProtectedRoute element={Pipelines} allowedRoles={[ 'owner', 'staff']} />} />
            <Route path="/pipelines/:id" element={<ProtectedRoute element={PipelineView} allowedRoles={['owner', 'staff']} />} />
            <Route path="/Contacts" element={<ProtectedRoute element={Contacts} allowedRoles={['owner', 'staff']} />} />
            <Route path="/products" element={<ProtectedRoute element={Products} allowedRoles={['owner', 'staff']} />} />
            <Route path="/Contacts/add" element={<ProtectedRoute element={AddContact} allowedRoles={['owner', 'staff']} />} />
            <Route path="/card/:id" element={<ProtectedRoute element={CardView} allowedRoles={['owner', 'staff']} />} />
            <Route path="/settings" element={<ProtectedRoute element={Settings} allowedRoles={['owner', 'staff','agency','admin']} />} />
            <Route path="/accounts" element={<ProtectedRoute element={Accounts} allowedRoles={['owner']} />} />
            <Route path="/register" element={!isAuthenticated ? <Registration setAuth={setAuth} /> : <Navigate replace to="/" />} />
            <Route path="/login" element={!isAuthenticated ? <Login setAuth={setAuth} /> : <Navigate replace to="/" />} />
            <Route path="/staff/login" element={!isAuthenticated ? <StaffLogin setAuth={setAuth} /> : <Navigate replace to="/dashboard" />} />
            <Route path="/owner/login" element={!isAuthenticated ? <OwnerLogin setAuth={setAuth} /> : <Navigate replace to="/dashboard" />} />


            <Route path="/agency/login" element={!isAuthenticated ? <AgencyLogin setAuth={setAuth} /> : <Navigate replace to="/agency/dashboard" />} />
            <Route path="/agency/accounts" element={<ProtectedRoute element={BusinessAccounts} allowedRoles={['agency']} />} />
            <Route path="/agency/plans" element={<ProtectedRoute element={AgencyPlans} allowedRoles={['agency']} />} />
            <Route path="/agency/businesses/:businessId/users" element={<ProtectedRoute element={BusinessUsers} allowedRoles={['agency']} />} />
            <Route path="/agency/dashboard" element={<ProtectedRoute element={AgencyDashboard} allowedRoles={['agency']} />} />



            <Route path="/admin/login" element={!isAuthenticated ? <AdminLogin setAuth={setAuth} /> : <Navigate replace to="/admin/dashboard" />} />
            <Route path="/admin/agencies" element={<ProtectedRoute element={AgencyAccounts} allowedRoles={['admin']} />} />  
            <Route path="/admin/agencies/:agencyId" element={<ProtectedRoute element={AgencyUsers} allowedRoles={['admin']} />} />
            <Route path="/admin/agencies/:agencyId/businesses" element={<ProtectedRoute element={AgencyBusinesses} allowedRoles={['admin']} />} />
            <Route path="/admin/settings" element={<ProtectedRoute element={AdminSettings} allowedRoles={['admin']} />} />
            <Route path="/admin/businesses/:businessId/users" element={<ProtectedRoute element={AgencyBusinessUsers} allowedRoles={['admin']} />} />
            <Route path="/admin/plans" element={<ProtectedRoute element={AdminPlans} allowedRoles={['admin']} />} />
            <Route path="/admin/dashboard" element={<ProtectedRoute element={AdminDashboard} allowedRoles={['admin']} />} />





          </Routes>
        </main>
      </div>
    </div>
  );
};

const App = () => (
  <BusinessProvider>
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  </BusinessProvider>
);

export default App;

