import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';
import { useBusinessContext } from '../context/BusinessContext';

const OwnerLogin = ({ setAuth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setBusinessContext } = useBusinessContext();

  const handleApiError = (error, context) => {
    console.error(`${context} error:`, error);
    console.error('Error response:', error.response);
    console.error('Error message:', error.message);
    console.error('Error stack:', error.stack);
    
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
      console.error('Error headers:', error.response.headers);
      
      switch (error.response.status) {
        case 400:
  if (error.response.data.message === 'Your account has been blocked by an administrator') {
    return "Your account has been blocked. Please contact the administrator.";
  }
  return "Bad request. Please check your input.";
        case 401:
          return "Authentication failed. Please check your credentials.";
        case 403:
          return "You don't have permission to access this resource.";
        case 404:
          return "The requested resource was not found.";
        case 500:
          return "Internal server error. Please try again later.";
        default:
          return `Server responded with status code ${error.response.status}. Please try again.`;
      }
    }
    
    if (error.request) {
      return "No response received from the server. Please check your internet connection.";
    }
    
    return `An unexpected error occurred: ${error.message}`;
  };
  
  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.API_URL}/api/auth/local`, {
        identifier: email,
        password
      }, { withCredentials: true });

      localStorage.setItem('jwt', response.data.jwt);

      const userResponse = await axios.get(`${config.API_URL}/api/users/me?populate[role][fields][0]=id&populate[business][fields][0]=id`, {
        headers: {
          Authorization: `Bearer ${response.data.jwt}`
        }
      });

      const roleResponse = await axios.get(`${config.API_URL}/api/users-permissions/roles/${userResponse.data.role.id}?fields=type`, {
        headers: {
          Authorization: `Bearer ${response.data.jwt}`
        }
      });

      if (roleResponse.data.role.type !== 'owner') {
        throw new Error('You do not have owner permissions');
      }

      const contextData = {
        businessId: userResponse.data.business?.id,
        userData: {
          id: userResponse.data.id,
          username: userResponse.data.username,
        },
        userRoles: {
          id: userResponse.data.role.id,
          type: roleResponse.data.role.type,
        },
        userType: 'owner'
      };

      localStorage.setItem('businessId', contextData.businessId);
      localStorage.setItem('userData', JSON.stringify(contextData.userData));
      localStorage.setItem('userRoles', JSON.stringify(contextData.userRoles));
      localStorage.setItem('userType', contextData.userType);

      setBusinessContext(contextData);

      setAuth(true);
      navigate('/dashboard');
    } catch (error) {
      const errorMessage = handleApiError(error, 'Owner Login');
      setError(errorMessage);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="w-full max-w-md">
        <form className="bg-white shadow-2xl rounded-lg px-12 pt-6 pb-8 mb-4 transform hover:scale-105 transition duration-300">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 mb-2">Welcome Back</h1>
            <p className="text-sm text-gray-600 mb-8">Enter your credentials to access your account</p>
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform hover:scale-105 transition duration-300 ease-in-out"
              type="button"
              onClick={handleLogin}
            >
              Sign In
            </button>
            <a className="inline-block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800" href="#">
              Forgot Password?
            </a>
          </div>
        </form>
        <p className="text-center text-white text-xs">
          &copy;2024 BusinessFlow. All rights reserved.
        </p>
      </div>
    </div>
  );
};


export default OwnerLogin;
