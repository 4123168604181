import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import config from '../config/config';
import Card from './Card';
import MoveCardsModal from './MoveCardsModal';
import { Link } from 'react-router-dom';


const PipelineView = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const [pipeline, setPipeline] = useState(null);
  const [stages, setStages] = useState([]);
  const [cards, setCards] = useState([]);
  const [editingStageId, setEditingStageId] = useState(null);
  const [editedStageName, setEditedStageName] = useState('');
  const [hoveredStageId, setHoveredStageId] = useState(null);
  const [newStageId, setNewStageId] = useState(null);
  
  const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
  const [successMessage, setSuccessMessage] = useState('');
  
  const [cardsByStage, setCardsByStage] = useState({});

  
 // Card States - Remove after standalone
  const [isCardSidebarOpen, setIsCardSidebarOpen] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [resetCardForm, setResetCardForm] = useState(false);

  const [isAddStagePopupOpen, setIsAddStagePopupOpen] = useState(false);
const [newStageName, setNewStageName] = useState('');
const [newStagePosition, setNewStagePosition] = useState(null);


const [selectedCards, setSelectedCards] = useState([]);

const [hoveredCardId, setHoveredCardId] = useState(null);

const [showConfirmation, setShowConfirmation] = useState(false);

const [stageToDelete, setStageToDelete] = useState(null);
const [showMoveCardsModal, setShowMoveCardsModal] = useState(false);
const [selectedStages, setSelectedStages] = useState([]);

// Number of deals & Value
const [stageMetrics, setStageMetrics] = useState({});
const [newStageType, setNewStageType] = useState('Open');

const [cardStageTime, setCardStageTime] = useState({});
const [stageEntries, setStageEntries] = useState([]);


const calculateAverageDealValue = () => {
  const wonStage = stages.find(stage => stage.attributes.type === 'Won');
  if (!wonStage) return 0;

  const wonDeals = cardsByStage[wonStage.id] || [];
  if (wonDeals.length === 0) return 0;

  const totalValue = wonDeals.reduce((sum, card) => {
    const price = card.attributes.product?.data?.attributes?.price || 0;
    return sum + parseFloat(price);
  }, 0);

  return totalValue / wonDeals.length;
};

const avgDealValue = calculateAverageDealValue();
const significantDealThreshold = avgDealValue * 1.2;

// Add this function inside the PipelineView component
const processStageEntries = (entries) => {
  const cardStageTime = {};
  const now = new Date();

  entries.forEach(entry => {
    const cardId = entry.attributes.card.data.id;
    const stageId = entry.attributes.stage;
    if (!cardStageTime[cardId]) cardStageTime[cardId] = {};
    if (!cardStageTime[cardId][stageId]) cardStageTime[cardId][stageId] = 0;

    const exitTime = entry.attributes.exitTime ? new Date(entry.attributes.exitTime) : now;
    const entryTime = new Date(entry.attributes.entryTime);
    cardStageTime[cardId][stageId] += exitTime - entryTime;
  });

  console.log('Processed Stage Entries:', cardStageTime);
  return cardStageTime;
};

// Add this function inside the PipelineView component
const fetchStageEntries = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/api/stage-entries`, {
      params: {
        'filters[card][pipeline][id][$eq]': id,
        'fields[0]': 'entryTime',
        'fields[1]': 'exitTime',
        'fields[2]': 'stage',
        'populate[card][fields][0]': 'id'
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Optimized Stage Entries Response:', response);
    const processedEntries = processStageEntries(response.data.data);
    setCardStageTime(processedEntries);
  } catch (error) {
    console.error('Error fetching stage entries:', error);
  }
};

const formatTime = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let formattedString;
  if (days > 0) formattedString = `${days} D`;
  else if (hours > 0) formattedString = `${hours} hr`;
  else if (minutes > 0) formattedString = `${minutes} min`;
  else formattedString = `${seconds}s`;

  return { formattedString, days };
};



const handleEditCard = (card) => {
  setEditingCard(card);
  setIsCardSidebarOpen(true);
};

const toggleCardSelection = (cardId) => {
  setSelectedCards(prev => 
    prev.includes(cardId) ? prev.filter(id => id !== cardId) : [...prev, cardId]
  );
};

const toggleStageSelection = (stageId) => {
  setSelectedStages(prev => 
    prev.includes(stageId) ? prev.filter(id => id !== stageId) : [...prev, stageId]
  );
  
  const cardsInStage = cardsByStage[stageId] || [];
  const cardIdsInStage = cardsInStage.map(card => card.id);
  
  setSelectedCards(prev => {
    const newSelection = new Set(prev);
    cardIdsInStage.forEach(cardId => {
      if (newSelection.has(cardId)) {
        newSelection.delete(cardId);
      } else {
        newSelection.add(cardId);
      }
    });
    return Array.from(newSelection);
  });
};


const deleteSelectedCards = async () => {
  try {
    await Promise.all(selectedCards.map(cardId => 
      axios.delete(`${config.API_URL}/api/cards/${cardId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      })
    ));

    setCardsByStage(prev => {
      const newState = {...prev};
      Object.keys(newState).forEach(stageId => {
        newState[stageId] = newState[stageId].filter(card => !selectedCards.includes(card.id));
      });
      return newState;
    });

    // Recalculate metrics after card deletion
    setCardsByStage(prevCardsByStage => {
      const updatedMetrics = calculateStageMetrics(prevCardsByStage);
      setStageMetrics(updatedMetrics);
      return prevCardsByStage;
    });

    setSelectedCards([]);
  } catch (error) {
    console.error('Error deleting cards:', error);
  }
};



// Modify the existing useEffect to include fetchStageEntries
useEffect(() => {
  const fetchInitialData = async () => {
    await fetchPipelineData();
    await fetchStages();
    await fetchStageEntries();
  };
  fetchInitialData();
}, [id]);

const calculateStageMetrics = (cardsByStage) => {
  const metrics = {};
  Object.entries(cardsByStage).forEach(([stageId, cards]) => {
    const dealCount = cards.length;
    const totalValue = cards.reduce((sum, card) => {
      const price = card.attributes.product?.data?.attributes?.price || 0;
      return sum + parseFloat(price);
    }, 0);
    metrics[stageId] = { dealCount, totalValue };
  });
  return metrics;
};


const fetchStages = async () => {
  try {
    const pipelineResponse = await axios.get(`${config.API_URL}/api/pipelines/${id}?populate[stages][populate]=*`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    
    if (!pipelineResponse.data?.data?.attributes?.stages?.data) {
      console.error('Unexpected pipeline response structure:', pipelineResponse);
      throw new Error('Invalid pipeline data structure');
    }

    const sortedStages = pipelineResponse.data.data.attributes.stages.data.sort((a, b) => a.attributes.order - b.attributes.order);
    setStages(sortedStages);

    const cardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[pipeline][id][$eq]=${id}&populate=*`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });

    if (!cardsResponse.data?.data) {
      console.error('Unexpected cards response structure:', cardsResponse);
      throw new Error('Invalid cards data structure');
    }

    const cardsByStage = cardsResponse.data.data.reduce((acc, card) => {
      const stageId = card.attributes.stage?.data?.id;
      if (!stageId) return acc;
      if (!acc[stageId]) acc[stageId] = [];
      
      acc[stageId].push({...card});
      return acc;
    }, {});


    Object.keys(cardsByStage).forEach(stageId => {
      cardsByStage[stageId].sort((a, b) => a.attributes.order - b.attributes.order);
    });

    setCardsByStage(cardsByStage);
    setCards(cardsResponse.data.data);

    const metrics = calculateStageMetrics(cardsByStage);
    setStageMetrics(metrics);

  } catch (error) {
    console.error('Error fetching pipeline data:', error);
    // You might want to set some error state here to display to the user
    // setError('Failed to fetch pipeline data. Please try again later.');
  }
};

const handleAddCard = async (cardData) => {
  console.log('handleAddCard called with:', cardData);

  try {

    // Find the stage object
    const stage = stages.find(s => s.id === cardData.stage);
    if (!stage) {
      throw new Error('Stage not found');
    }


    // Fetch all cards in the target stage
    const existingCardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[stage][id][$eq]=${cardData.stage}&sort=order:asc`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Existing cards response:', existingCardsResponse);

    const existingCards = existingCardsResponse.data.data;

    // Create the new card with order 0
    const response = await axios.post(`${config.API_URL}/api/cards`, {
      data: {
        name: cardData.name,
        description: cardData.description,
        contact: cardData.contact,
        stage: cardData.stage,
        users_permissions_user: cardData.users_permissions_user,
        product: cardData.product,
        business: cardData.business,
        pipeline: id,
        order: 0,
        type: stage.attributes.type
      }
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });

    console.log('API response:', response.data);

    const newCard = {
      ...response.data.data || response.data,
      attributes: {
        ...response.data.data?.attributes || response.data.attributes,
        contact: {
          data: {
            id: cardData.contact.id,
            attributes: {
              name: cardData.contact.name
            }
          }
        }
      }
    };

    // Update orders of existing cards
    await Promise.all(existingCards.map((card, index) => 
      axios.put(`${config.API_URL}/api/cards/${card.id}`, {
        data: { order: index + 1 }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      })
    ));

    setCardsByStage(prevCardsByStage => {
      const newCardsByStage = { ...prevCardsByStage };
      const stageId = newCard.attributes.stage?.data?.id || cardData.stage;
      if (!newCardsByStage[stageId]) {
        newCardsByStage[stageId] = [];
      }
      newCardsByStage[stageId] = [newCard, ...newCardsByStage[stageId]];
      return newCardsByStage;
    });

    setCards(prevCards => [newCard, ...prevCards]);

    setIsCardSidebarOpen(false);
    setSuccessMessage('Card added successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
    setResetCardForm(prev => !prev);
  } catch (error) {
    console.error('Error adding new card:', error);
  }
};

const hasWonOrLostStage = stages.some(stage => 
  (stage.attributes.type === 'Won' || stage.attributes.type === 'Lost') && 
  (cardsByStage[stage.id]?.length > 0)
);

const calculateTimeInPipeline = (card) => {
  const createdAt = new Date(card.attributes.createdAt);
  const now = new Date();
  return formatTime(now - createdAt);
};


const handleUpdateCard = async (updatedCardData) => {
  setIsLoading(true);
  try {
    const oldStageId = editingCard.attributes.stage.data.id;
    const newStageId = updatedCardData.stage;

    // Set order to 0 for the updated card
    updatedCardData.order = 0;

    // Update the card
    await axios.put(`${config.API_URL}/api/cards/${editingCard.id}`, {
      data: updatedCardData
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });

    // If the stage has changed
    if (oldStageId !== newStageId) {
      // Update order of cards in the new stage
      const newStageCardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[stage][id][$eq]=${newStageId}&sort=order:asc`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const cardsInNewStage = newStageCardsResponse.data.data;

      const updateNewStageCards = cardsInNewStage
        .filter(card => card.id !== editingCard.id)
        .map((card, index) => 
          axios.put(`${config.API_URL}/api/cards/${card.id}`, {
            data: { order: index + 1 }
          }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          })
        );

      // Update order of cards in the original stage
      const oldStageCardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[stage][id][$eq]=${oldStageId}&sort=order:asc`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const cardsInOldStage = oldStageCardsResponse.data.data;

      const updateOldStageCards = cardsInOldStage
        .filter(card => card.id !== editingCard.id)
        .map((card, index) => 
          axios.put(`${config.API_URL}/api/cards/${card.id}`, {
            data: { order: index }
          }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          })
        );

      await Promise.all([...updateNewStageCards, ...updateOldStageCards]);
    }

    await fetchStages();

    setIsCardSidebarOpen(false);
    setSuccessMessage('Card updated successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
    setEditingCard(null);
  } catch (error) {
    console.error('Error updating card:', error);
    setSuccessMessage('Error updating card. Please try again.');
  } finally {
    setIsLoading(false);
  }
};



  


  const fetchPipelineData = async () => {
    try {
      const pipelineResponse = await axios.get(`${config.API_URL}/api/pipelines/${id}?populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setPipeline(pipelineResponse.data.data);
      
      // Sort stages by their order attribute
const sortedStages = pipelineResponse.data.data.attributes.stages.data.sort((a, b) => a.attributes.order - b.attributes.order);
setStages(sortedStages);
  
      console.log('Pipeline ID:', id);
      const cardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[pipeline][id][$eq]=${id}&populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Cards API response:', cardsResponse.data);

      setCards(cardsResponse.data.data);


      const cardsByStage = cardsResponse.data.data.reduce((acc, card) => {
        const stageId = card.attributes.stage?.data?.id;
        if (!acc[stageId]) acc[stageId] = [];
        acc[stageId].push(card);
        return acc;
      }, {});

      // Sort cards within each stage by their order
Object.keys(cardsByStage).forEach(stageId => {
  cardsByStage[stageId].sort((a, b) => a.attributes.order - b.attributes.order);
});
    
      setCardsByStage(cardsByStage);


    } catch (error) {
      console.error('Error fetching pipeline data:', error);
    }

    
  };


  const checkStageTypeRestriction = (type) => {
    const wonStageExists = stages.some(stage => stage.attributes.type === 'Won');
    const lostStageExists = stages.some(stage => stage.attributes.type === 'Lost');
    
    if ((type === 'Won' && wonStageExists) || (type === 'Lost' && lostStageExists)) {
      return false;
    }
    return true;
  };

  
const handleAddStage = (afterStageId) => {
  const currentIndex = stages.findIndex(stage => stage.id === afterStageId);
  setNewStagePosition(currentIndex + 1);
  setIsAddStagePopupOpen(true);
};

const calculateWinLostRatio = () => {
  const wonStage = stages.find(stage => stage.attributes.type === 'Won');
  const lostStage = stages.find(stage => stage.attributes.type === 'Lost');
  
  const wonDeals = wonStage ? cardsByStage[wonStage.id]?.length || 0 : 0;
  const lostDeals = lostStage ? cardsByStage[lostStage.id]?.length || 0 : 0;
  
  if (wonDeals === 0) return null;
  
  const ratio = wonDeals / (wonDeals + lostDeals);
  return (ratio * 100).toFixed(2) + '%';
};

const calculateAverageSalesCycle = () => {
  let totalMilliseconds = 0;
  let closedDeals = 0;

  Object.values(cardsByStage).flat().forEach(card => {
    if (card.attributes.type === 'Won' || card.attributes.type === 'Lost') {
      const createdAt = new Date(card.attributes.createdAt);
      const closedAt = new Date(card.attributes.closedAt || new Date());
      totalMilliseconds += closedAt - createdAt;
      closedDeals++;
    }
  });

  if (closedDeals === 0) return null;
  
  const averageMilliseconds = totalMilliseconds / closedDeals;
  return formatTime(averageMilliseconds);
};



  

const createNewStage = async () => {
  if (!newStageName.trim()) return;
  setIsLoading(true);

  try {
    if (!checkStageTypeRestriction(newStageType)) {
      setIsLoading(false);
      setSuccessMessage('Only one Won or Lost stage allowed.');
      setTimeout(() => setSuccessMessage(''), 3000);
      return;
    }

    const response = await axios.post(`${config.API_URL}/api/stages`, {
      data: {
        name: newStageName,
        pipeline: id,
        order: newStagePosition,
        business: businessId,
        type: newStageType
      }
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });

    console.log('New stage response:', response);

    const newStage = response.data.data;
    let newStages = [...stages];
    newStages.splice(newStagePosition, 0, newStage);

    // Recalculate order for all stages
    newStages.forEach((stage, index) => {
      stage.attributes.order = index;
    });

    setStages(newStages);

    // Update orders for all stages
    await Promise.all(newStages.map((stage) => {
      return axios.put(`${config.API_URL}/api/stages/${stage.id}`, {
        data: { order: stage.attributes.order }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    }));

    setIsLoading(false);
    setIsAddStagePopupOpen(false);
    setNewStageName('');
    setNewStagePosition(null);
    setNewStageType('Open');

  } catch (error) {
    console.error('Error creating or updating stages:', error);
    setIsLoading(false);
  }
};


 

  const handleNewStageNameChange = (e, stageId) => {
    const updatedStages = stages.map(stage =>
      stage.id === stageId ? { ...stage, attributes: { ...stage.attributes, name: e.target.value } } : stage
    );
    setStages(updatedStages);
  };

  const handleNewStageSave = async (stageId) => {
    const newStage = stages.find(stage => stage.id === stageId);
    if (!newStage.attributes.name.trim()) return;
  
    try {
      const response = await axios.post(`${config.API_URL}/api/stages`, {
        data: {
          name: newStage.attributes.name,
          pipeline: id,
          order: newStage.attributes.order, // Use the calculated order
          business: businessId
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      const savedStage = response.data.data;
      const updatedStages = stages.map(stage =>
        stage.id === stageId ? { ...savedStage, isNew: false } : stage
      );
      setStages(updatedStages);
      setNewStageId(null);
    } catch (error) {
      console.error('Error saving new stage:', error);
    }
  };

  const handleStageNameEdit = (stageId, currentName) => {
    setEditingStageId(stageId);
    setEditedStageName(currentName);
  };

  const handleStageNameChange = (e) => {
    setEditedStageName(e.target.value);
  };

  const handleStageNameSave = async (stageId) => {
    try {
      await axios.put(`${config.API_URL}/api/stages/${stageId}`, {
        data: { name: editedStageName }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setStages(stages.map(stage =>
        stage.id === stageId ? { ...stage, attributes: { ...stage.attributes, name: editedStageName } } : stage
      ));
      setEditingStageId(null);
    } catch (error) {
      console.error('Error updating stage name:', error);
    }
  };




const handleStageDelete = async (stageId) => {
  const cardsInStage = cardsByStage[stageId] || [];
  
  if (cardsInStage.length > 0) {
    setStageToDelete(stageId);
    setShowMoveCardsModal(true);
  } else {
    await deleteStage(stageId);
  }
};

const deleteStage = async (stageId) => {
  try {
    await axios.delete(`${config.API_URL}/api/stages/${stageId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    setStages(stages.filter(stage => stage.id !== stageId));
    const updatedCardsByStage = { ...cardsByStage };
    delete updatedCardsByStage[stageId];
    setCardsByStage(updatedCardsByStage);
  } catch (error) {
    console.error('Error deleting stage:', error);
  }
};

const moveCardsAndDeleteStage = async (destinationStageId) => {
  const cardsToMove = cardsByStage[stageToDelete] || [];
  try {
    await Promise.all(cardsToMove.map(card => 
      axios.put(`${config.API_URL}/api/cards/${card.id}`, {
        data: { stage: destinationStageId }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      })
    ));

    setCardsByStage(prev => ({
      ...prev,
      [destinationStageId]: [...(prev[destinationStageId] || []), ...cardsToMove],
      [stageToDelete]: []
    }));

    await deleteStage(stageToDelete);
    setShowMoveCardsModal(false);
  } catch (error) {
    console.error('Error moving cards and deleting stage:', error);
  }
};



const onDragEnd = async (result) => {
  const { source, destination, type } = result;

  if (!destination) return;

  if (type === 'STAGE') {
      const newStages = Array.from(stages);
      const [reorderedStage] = newStages.splice(source.index, 1);
      newStages.splice(destination.index, 0, reorderedStage);

      setStages(newStages);

      try {
          await Promise.all(newStages.map((stage, index) => 
              axios.put(`${config.API_URL}/api/stages/${stage.id}`, {
                  data: { order: index }
              }, {
                  headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
              })
          ));
      } catch (error) {
          console.error('Error updating stage order:', error);
      }

    } else if (type === 'CARD') {
      const sourceStageId = source.droppableId.split('-')[1];
      const destStageId = destination.droppableId.split('-')[1];
  
      const newCardsByStage = { ...cardsByStage };
  
      if (!newCardsByStage[sourceStageId]) newCardsByStage[sourceStageId] = [];
      if (!newCardsByStage[destStageId]) newCardsByStage[destStageId] = [];
  
      const [movedCard] = newCardsByStage[sourceStageId].splice(source.index, 1);
      newCardsByStage[destStageId].splice(destination.index, 0, movedCard);
  
      setCardsByStage(newCardsByStage);
  
      const updatedMetrics = calculateStageMetrics(newCardsByStage);
      setStageMetrics(updatedMetrics);
  
      try {
        const destStageCards = newCardsByStage[destStageId];
        const destStage = stages.find(stage => stage.id.toString() === destStageId);
        const isClosingStage = destStage.attributes.type === 'Won' || destStage.attributes.type === 'Lost';
  
        const now = new Date();
  
        // Update the moved card
        const updateData = { 
          order: destination.index,
          stage: destStageId,
          type: destStage.attributes.type
        };
  
        if (isClosingStage) {
          updateData.closedAt = now;
        }
  
        // Update the card
        const cardResponse = await axios.put(`${config.API_URL}/api/cards/${movedCard.id}`, {
          data: updateData
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
  
        console.log('Card update response:', cardResponse);
  
        if (cardResponse.status !== 200) {
          console.error('Unexpected status code:', cardResponse.status);
          console.error('Response data:', cardResponse.data);
        }

        // Close the previous StageEntry
      const previousEntries = await axios.get(`${config.API_URL}/api/stage-entries?filters[card][id][$eq]=${movedCard.id}&filters[exitTime][$null]=true`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      if (previousEntries.data.data.length > 0) {
        const previousEntry = previousEntries.data.data[0];
        await axios.put(`${config.API_URL}/api/stage-entries/${previousEntry.id}`, {
          data: {
            exitTime: now
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }

      // Create new StageEntry for the new stage
      const stageEntryResponse = await axios.post(`${config.API_URL}/api/stage-entries`, {
        data: {
          entryTime: now,
          card: movedCard.id,
          stage: parseInt(destStageId),
          pipeline: id,
          business: businessId
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      console.log('StageEntry create response:', stageEntryResponse);
  
  
  
       
        // Update other cards in the destination stage
        await Promise.all(destStageCards.map((card, index) => 
          axios.put(`${config.API_URL}/api/cards/${card.id}`, {
            data: { order: index }
          }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          })
        ));
  
        // If source and destination stages are different, update source stage cards too
        if (sourceStageId !== destStageId) {
          const sourceStageCards = newCardsByStage[sourceStageId];
          await Promise.all(sourceStageCards.map((card, index) => 
            axios.put(`${config.API_URL}/api/cards/${card.id}`, {
              data: { order: index }
            }, {
              headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
            })
          ));
        }
      } catch (error) {
        console.error('Error updating card stage and order:', error);
      }
    }
  };
      



  console.log('isCardSidebarOpen:', isCardSidebarOpen);

    const calculateTotalRevenue = () => {
      return Object.values(stageMetrics).reduce((total, metrics) => {
        return total + (metrics.totalValue || 0);
      }, 0);
    };

const calculateWonRevenue = () => {
  const wonStage = stages.find(stage => stage.attributes.type === 'Won');
  return wonStage ? (stageMetrics[wonStage.id]?.totalValue || 0) : 0;
};


const calculateLostRevenue = () => {
  const lostStage = stages.find(stage => stage.attributes.type === 'Lost');
  return lostStage ? (stageMetrics[lostStage.id]?.totalValue || 0) : 0;
};

const RevenueBar = ({ total, won, lost }) => {
  const openPercentage = ((total - won - lost) / total) * 100;
  const wonPercentage = (won / total) * 100;
  const lostPercentage = (lost / total) * 100;
};

// In your main component:
const totalRevenue = calculateTotalRevenue();
const wonRevenue = calculateWonRevenue();
const lostRevenue = calculateLostRevenue();

function formatMoney(amount) {
  if (amount >= 10000000) {
    return (amount / 10000000).toFixed(2) + ' Crores';
  } else if (amount >= 100000) {
    return (amount / 100000).toFixed(2) + ' Lakhs';
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(2) + ' K';
  } else {
    return amount.toFixed(2);
  }
}

const calculateConversionRate = () => {
  const wonStage = stages.find(stage => stage.attributes.type === 'Won');
  const totalDeals = Object.values(cardsByStage).flat().length;
  const wonDeals = wonStage ? (cardsByStage[wonStage.id]?.length || 0) : 0;
  
  if (totalDeals === 0) return '0%';
  
  const rate = (wonDeals / totalDeals) * 100;
  return rate.toFixed(1) + '%';
};



  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

return (
  <div className="flex flex-col h-screen">

<div className="bg-white shadow-sm border-b border-gray-200 py-3 px-6">
  <div className="flex items-center justify-between space-x-4">
    <h1 className="text-2xl font-bold text-indigo-700 truncate flex-shrink-0">
      {pipeline?.attributes.name}
    </h1>
    
    <div className="flex items-center space-x-6 flex-grow justify-end">

      <div className="flex-grow max-w-3xl">

      {hasWonOrLostStage && (

        <div className="relative h-6 bg-gray-200 rounded-full overflow-hidden mb-1">
        

          <div className="absolute h-full bg-indigo-500" style={{width: '33.33%'}}>
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-bold">
              Open: {formatMoney(totalRevenue - wonRevenue - lostRevenue)} ({((totalRevenue - wonRevenue - lostRevenue) / totalRevenue * 100).toFixed(1)} %)
            </span>
          </div>
          <div className="absolute h-full bg-green-500" style={{width: '33.33%', left: '33.33%'}}>
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-bold">
            Converted: {formatMoney(wonRevenue)} ({(wonRevenue / totalRevenue * 100).toFixed(1)} %)
            </span>
          </div>
          <div className="absolute h-full bg-red-500" style={{width: '33.33%', right: '0'}}>
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-bold">
            Dropped: {formatMoney(lostRevenue)} ({(lostRevenue / totalRevenue * 100).toFixed(1)} %)
            </span>
          </div>
        </div>


)}


      </div>

      {selectedCards.length === 0 && hasWonOrLostStage && calculateAverageSalesCycle() !== 'N/A' && calculateAverageSalesCycle() !== '0 days' && (
  <div className="flex-shrink-0 flex space-x-4">
    <div className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-lg text-center">
      <div className="text-lg font-bold">{formatMoney(calculateAverageDealValue())}</div>
      {/* <div className="text-lg font-bold">60 Lakhs</div> */}
      <div className="text-xs font-medium">Avg. Deal Value</div>
    </div>
    
    <div className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-lg text-center">
      <div className="text-lg font-bold">{calculateConversionRate()}</div>
      <div className="text-xs font-medium">Conversion Rate</div>
    </div>

    <div className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-lg text-center">
      <div className="text-lg font-bold">{calculateAverageSalesCycle().formattedString}</div>
      {/* <div className="text-lg font-bold">12 Days</div> */}
      <div className="text-xs font-medium">Avg. Sales Cycle</div>
    </div>

  </div>
)}

      
      <button
        onClick={() => setIsCardSidebarOpen(true)}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 flex-shrink-0"
      >
        + Add Deal
      </button>
    </div>


  </div>

  {selectedCards.length > 0 && (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-white rounded-lg shadow-lg p-4 flex space-x-4">
        <button
          onClick={() => setShowConfirmation(true)}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
        >
          Delete Selected ({selectedCards.length})
        </button>
        <button
          onClick={() => setSelectedCards([])}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</div>


{isAddStagePopupOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg relative">
      <h2 className="text-xl font-bold mb-4">Add New Stage</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
        createNewStage();
      }}>
        <input
          type="text"
          value={newStageName}
          onChange={(e) => setNewStageName(e.target.value)}
          placeholder="Enter stage name"
          className="border p-2 mb-4 w-full"
        />

{checkStageTypeRestriction('Won') || checkStageTypeRestriction('Lost') ? (
  <select
    value={newStageType}
    onChange={(e) => setNewStageType(e.target.value)}
    className="border p-2 mb-4 w-full"
  >
    <option value="Open">Open</option>
    {checkStageTypeRestriction('Won') && <option value="Won">Won</option>}
    {checkStageTypeRestriction('Lost') && <option value="Lost">Lost</option>}
  </select>
) : (
  <input type="hidden" value="Open" />
)}



        <div className="flex justify-end">
        
          <button
            type="button"
            onClick={() => setIsAddStagePopupOpen(false)}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add Stage'}
          </button>
        </div>
      </form>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      )}
    </div>
  </div>
)}



      


    <DragDropContext onDragEnd={onDragEnd}>
<Droppable droppableId="stages" direction="horizontal" type="STAGE">
  {(provided) => (
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
      className="flex-1 flex overflow-x-auto p-4"
    >
      {stages.length === 0 ? (
        <div className="flex items-center justify-center w-full">
          <button
            onClick={() => handleAddStage(null)}
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Your Stages
          </button>
        </div>
      ) : (
        stages.map((stage, index) => (
          <Draggable key={stage.id} draggableId={stage.id.toString()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`flex-shrink-0 w-64 bg-gray-100 p-4 mr-4 flex flex-col h-full rounded-lg shadow relative ${
                  stage.attributes.type === 'Won' ? 'border-green-500 border-2' :
                  stage.attributes.type === 'Lost' ? 'border-red-500 border-2' : ''
                }`}
                onMouseEnter={() => setHoveredStageId(stage.id)}
                onMouseLeave={() => setHoveredStageId(null)}
              >



                {/* ... (stage header code remains the same) ... */}
                
                <div className="flex justify-between items-center mb-2">
                  {editingStageId === stage.id || stage.isNew ? (
                    <input
                      type="text"
                      value={stage.isNew ? stage.attributes.name : editedStageName}
                      onChange={(e) => stage.isNew ? handleNewStageNameChange(e, stage.id) : handleStageNameChange(e)}
                      onBlur={() => stage.isNew ? handleNewStageSave(stage.id) : handleStageNameSave(stage.id)}
                      onKeyPress={(e) => e.key === 'Enter' && (stage.isNew ? handleNewStageSave(stage.id) : handleStageNameSave(stage.id))}
                      className="font-bold text-lg w-full bg-white p-1 rounded"
                      autoFocus
                      placeholder="Enter stage name"
                    />
                  ) : (
                    <h2 className="font-bold text-lg">{stage.attributes.name}</h2>
                    
                  )}
                  {hoveredStageId === stage.id && !stage.isNew && (
                    <div className="flex">

<button onClick={() => toggleStageSelection(stage.id)} className="text-gray-600 hover:text-indigo-600 mr-2">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
      </svg>
    </button>

                      <button onClick={() => handleStageNameEdit(stage.id, stage.attributes.name)} className="text-gray-600 hover:text-gray-800 mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button onClick={() => handleStageDelete(stage.id)} className="text-gray-600 hover:text-red-600 mr-2">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
    </button>
                      <button onClick={() => handleAddStage(stage.id)} className="text-gray-600 hover:text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>

                {/* ... (stage header code remains the same) ... */}

        <div className="flex space-x-2 mb-2">
  <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
    Deals: {stageMetrics[stage.id]?.dealCount || 0}
  </span>
  <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
    Value: Rs.{formatMoney(stageMetrics[stage.id]?.totalValue || 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
  </span>
</div>


                <Droppable droppableId={`stage-${stage.id}`} type="CARD">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex-1 overflow-y-auto"
                    >


{(cardsByStage[stage.id] || []).map((card, index) => (
  
//   <Draggable key={`${card.id}-${index}`} draggableId={`card-${card.id}`} index={index}>
//     {(provided) => (
//       <div
//         ref={provided.innerRef}
//         {...provided.draggableProps}
//         {...provided.dragHandleProps}
//         className={`p-2 mb-2 rounded shadow relative transition-colors duration-200 ${
//           (selectedCards.includes(card.id) && !card.isNew) || selectedStages.includes(stage.id)
//             ? 'bg-indigo-100 border-2 border-indigo-500'
//             : 'bg-white'
//         }`}
//         onMouseEnter={() => setHoveredCardId(card.id)}
//         onMouseLeave={() => setHoveredCardId(null)}
//       >
//         {hoveredCardId === card.id && (
//           <div className="absolute top-2 right-2 flex">
//           <input
//             type="checkbox"
//             className="mr-2"
//             checked={selectedCards.includes(card.id)}
//             onChange={() => toggleCardSelection(card.id)}
//           />
//           <button onClick={() => handleEditCard(card)} className="text-gray-600 hover:text-indigo-600">
//             <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//               <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
//             </svg>
//           </button>
//         </div>
//         )}
//           <Link to={`/card/${card.id}`} className="flex items-center hover:underline text-indigo-600">
//     <h3 className="font-semibold">{card.attributes.name}</h3>
//     {hoveredCardId === card.id && (
//       <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
//       </svg>
//     )}
//     </Link>
//         <p className="text-sm text-gray-600">
//           {card.attributes.contact?.data?.attributes?.name || 'No contact'}
//         </p>

// {card.attributes.product?.data && (
//     <div className="mt-2 text-xs text-gray-500 flex justify-between items-end">
//       <span className="font-medium">{card.attributes.product.data.attributes.name}</span>
//       <span className="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">{card.attributes.product.data.attributes.price}</span>
//     </div>
//   )}

// <div className="mt-2 text-xs text-gray-500">
//           Time in stage: {formatTime(cardStageTime[card.id]?.[stage.id] || 0)}
// </div>

// <div className="mt-2 text-xs text-gray-500">
//   Time in pipeline: {calculateTimeInPipeline(card)}
// </div>


//       </div>
//     )}
//   </Draggable>

<Draggable key={`${card.id}-${index}`} draggableId={`card-${card.id}`} index={index}>
  {(provided) => (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`p-2 mb-2 rounded shadow relative transition-colors duration-200 
        ${(selectedCards.includes(card.id) && !card.isNew) || selectedStages.includes(stage.id)
          ? 'bg-indigo-100 border-2 border-indigo-500'
          : card.attributes.product?.data?.attributes?.price >= significantDealThreshold
            ? 'bg-gradient-to-r from-green-50 to-white border-l-4 border-green-500'
            : 'bg-white'
        }`}
      onMouseEnter={() => setHoveredCardId(card.id)}
      onMouseLeave={() => setHoveredCardId(null)}
    >
      <div className="absolute top-2 right-2 transition-opacity duration-200">
        {card.attributes.product?.data && (
          <span className={`bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs ${hoveredCardId === card.id ? 'opacity-0' : 'opacity-100'}`}>
            {formatMoney(card.attributes.product.data.attributes.price)}
          </span>
        )}
        {hoveredCardId === card.id && (
            <div className={`flex absolute top-1 right-1 ${hoveredCardId === card.id ? 'opacity-100' : 'opacity-0'}`}>
    
            <input
              type="checkbox"
              className="mr-2"
              checked={selectedCards.includes(card.id)}
              onChange={() => toggleCardSelection(card.id)}
            />
            <button onClick={() => handleEditCard(card)} className="text-gray-600 hover:text-indigo-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </button>
          </div>
        )}
      </div>
      <Link to={`/card/${card.id}`} className="flex items-center hover:underline text-indigo-600">
        <h3 className="font-semibold truncate">{card.attributes.name.slice(0,10)}...</h3>
        {hoveredCardId === card.id && (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        )}
      </Link>
      <p className="text-sm text-gray-600 truncate">
        {card.attributes.contact?.data?.attributes?.name || 'No contact'}
      </p>
      {card.attributes.product?.data && (
        <div className="mt-1 text-xs text-gray-500">
          <span className="font-medium truncate">{card.attributes.product.data.attributes.name}</span>
        </div>
      )}
      <div className="mt-1 text-xs text-gray-500 flex justify-between">
      {/* <span title="Time in stage">🕒 {formatTime(cardStageTime[card.id]?.[stage.id] || 0)}</span> */}
        
        
      {!['Won', 'Lost'].includes(stage.attributes.type) && (
  <>
    <span title="Time in stage">🕒</span>
    <span 
      title="Time in pipeline"
      className={`px-2 py-1 rounded ${
        calculateTimeInPipeline(card).days > 12 
          ? 'bg-red-100 text-red-700 font-bold' 
          : 'text-gray-500'
      }`}
    >
      {calculateTimeInPipeline(card).formattedString}
      {calculateTimeInPipeline(card).days > 12 && ' ⚠️'}
    </span>
  </>
)}



      </div>
    </div>
  )}
</Draggable>



))}



                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </Draggable>

          
        ))
      )}
      {provided.placeholder}
    </div>
  )}
</Droppable>




    </DragDropContext>

 <Card
  isOpen={isCardSidebarOpen}
  onClose={() => {
    setIsCardSidebarOpen(false);
    setEditingCard(null);
  }}
  onSave={(cardData) => {
    if (editingCard) {
      handleUpdateCard(cardData);
    } else {
      handleAddCard(cardData);
    }
  }}
  stages={stages}
  businessId={businessId}
  resetTrigger={resetCardForm}
  pipelineId={id}
  editingCard={editingCard}
/>



{successMessage && (
  <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
    {successMessage}
  </div>
)}


{showConfirmation && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-5 rounded-lg shadow-xl">
      <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
      <p className="mb-4">Warning: This action will delete the selected cards. This cannot be undone.</p>
      <div className="flex justify-end">
        <button 
          onClick={() => setShowConfirmation(false)}
          className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
        <button 
          onClick={() => { deleteSelectedCards(); setShowConfirmation(false); }}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}



{showMoveCardsModal && (
  <MoveCardsModal
    stages={stages.filter(stage => stage.id !== stageToDelete)}
    onMove={moveCardsAndDeleteStage}
    onCancel={() => setShowMoveCardsModal(false)}
  />
)}



  </div>

  
);
};

export default PipelineView;
