import React, { useState, useEffect } from 'react';
import { useBusinessContext } from '../context/BusinessContext';
import axios from 'axios';
import config from '../config/config';
import DateRangeSelector from './DateRangeSelector';
import { startOfMonth } from 'date-fns';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const { businessId } = useBusinessContext();
  const [pipelines, setPipelines] = useState([]);
  const [selectedPipelineId, setSelectedPipelineId] = useState('all');
  const [revenue, setRevenue] = useState(0);
  const [topProducts, setTopProducts] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [topContacts, setTopContacts] = useState([]);
  const [dateRange, setDateRange] = useState({ from: startOfMonth(new Date()), to: new Date() });
  const [loading, setLoading] = useState(true);
  const [isEditingTarget, setIsEditingTarget] = useState(false);

  const REVENUE_TARGET = 80000000; // 8 Crores in rupees

  const [dailyRevenue, setDailyRevenue] = useState(0);
  const [targetRevenue, setTargetRevenue] = useState(dailyRevenue * 360 * 2);

  const [editValue, setEditValue] = useState(targetRevenue);




  useEffect(() => {
    fetchPipelines();
  }, [businessId]);

  useEffect(() => {
    fetchRevenue();
  }, [businessId, dateRange, selectedPipelineId]);

  const handleTargetChange = (value) => {
    setEditValue(Number(value));
  };
  
  const handleTargetSubmit = () => {
    if (editValue >= dailyRevenue * 360) {
      setTargetRevenue(editValue);
      setIsEditingTarget(false);
    }
  };
  
  
  

  
  

  const chartColors = [
    '#4C51BF', '#ED64A6', '#48BB78', '#ECC94B', '#ED8936', 
    '#9F7AEA', '#667EEA', '#38B2AC', '#F56565', '#D53F8C'
  ];

  const createHorizontalBarChartData = (data, label) => ({
    labels: data.map(item => item.name),
    datasets: [{
      label: label,
      data: data.map(item => item.revenue),
      backgroundColor: chartColors,
      borderColor: chartColors,
      borderWidth: 1,
    }],
  });

  const createPieChartData = (data) => ({
    labels: data.map(item => item.name),
    datasets: [{
      data: data.map(item => item.percentage),
      backgroundColor: chartColors,
      borderColor: '#ffffff',
      borderWidth: 2,
    }],
  });

  const horizontalBarChartOptions = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Sales Data', font: { size: 16 } },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
      },
      y: {
        grid: { display: false },
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' },
      title: { display: true, text: 'Sales Percentage', padding: { top: 10, bottom: 30 }, font: { size: 16 } },
    },
  };

  const fetchPipelines = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/pipelines`, {
        params: {
          'filters[business][id][$eq]': businessId,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setPipelines(response.data.data);
    } catch (error) {
      console.error('Error fetching pipelines:', error);
    }
  };

  const handlePipelineChange = (event) => {
    setSelectedPipelineId(event.target.value);
  };

  const fetchRevenue = async () => {
    setLoading(true);

    let earliestSaleDate = new Date(); // Initialize with today

    try {
      const params = {
        'filters[business][id][$eq]': businessId,
        'filters[type][$eq]': 'Won',
        'filters[closedAt][$gte]': dateRange.from.toISOString(),
        'filters[closedAt][$lte]': dateRange.to.toISOString(),
        'populate': ['product', 'users_permissions_user', 'contact'],
      };

      if (selectedPipelineId !== 'all') {
        params['filters[pipeline][id][$eq]'] = selectedPipelineId;
      }

      const response = await axios.get(`${config.API_URL}/api/cards`, {
        params,
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      const productRevenue = {};
      const userRevenue = {};
      const contactRevenue = {};
      let totalRevenue = 0;

      response.data.data.forEach(card => {
        const product = card.attributes.product?.data?.attributes;
        const user = card.attributes.users_permissions_user?.data?.attributes;
        const contact = card.attributes.contact?.data?.attributes;
        const closedAt = new Date(card.attributes.closedAt);
    if (closedAt < earliestSaleDate) {
      earliestSaleDate = closedAt;
    }

        if (product) {
          const price = parseFloat(product.price) || 0;
          productRevenue[product.name] = (productRevenue[product.name] || 0) + price;
          totalRevenue += price;

          if (user) {
            userRevenue[user.username] = (userRevenue[user.username] || 0) + price;
          }

          if (contact) {
            contactRevenue[contact.name] = (contactRevenue[contact.name] || 0) + price;
          }
        }
      });

      
  

      const sortAndMapData = (data) => Object.entries(data)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 5)
        .map(([name, revenue]) => ({
          id: name,
          name,
          revenue,
          percentage: (revenue / totalRevenue) * 100
        }));

        const daysSinceFirstSale = Math.floor((new Date() - earliestSaleDate) / (1000 * 60 * 60 * 24));
      const dailyRevenue = totalRevenue / daysSinceFirstSale;

      setRevenue(totalRevenue);
      setTopProducts(sortAndMapData(productRevenue));
      setTopUsers(sortAndMapData(userRevenue));
      setTopContacts(sortAndMapData(contactRevenue));
      setDailyRevenue(dailyRevenue);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add this function near the top of your file, after the imports and before the Dashboard component definition

function formatMoney(amount) {
  if (amount >= 10000000) {
    return (amount / 10000000).toFixed(2) + ' Crores';
  } else if (amount >= 100000) {
    return (amount / 100000).toFixed(2) + ' Lakhs';
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(2) + ' K';
  } else {
    return amount.toFixed(2);
  }
}





  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8 flex flex-col md:flex-row justify-between items-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4 md:mb-0">Business Dashboard</h1>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-center">
            <div className="w-full sm:w-64">
              <select
                id="pipeline-select"
                value={selectedPipelineId}
                onChange={handlePipelineChange}
                className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="all">All Pipelines</option>
                {pipelines.map((pipeline) => (
                  <option key={pipeline.id} value={pipeline.id}>
                    {pipeline.attributes.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative z-10">
              <DateRangeSelector dateRange={dateRange} onRangeSelect={setDateRange} />
            </div>
          </div>
        </div>


{/* 
<div className="bg-white rounded-lg shadow-md p-6 mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-700">Revenue Forecast</h2>
  <div className="flex flex-col space-y-4">
    <div className="flex justify-between items-center">
      <div className="text-lg">
        Projected Annual Revenue: <span className="font-bold text-indigo-600">₹{formatMoney(dailyRevenue * 360)}</span>
      </div>
    </div>

    <div className="text-lg">
  Target Revenue: 
  {!isEditingTarget ? (
    <>
      <span className="font-bold text-indigo-600">₹{formatMoney(targetRevenue)}</span>
      <button onClick={() => {
        setEditValue(targetRevenue);
        setIsEditingTarget(true);
      }} className="ml-2 inline-flex items-center">
        <PencilIcon className="h-4 w-4 text-gray-400 hover:text-indigo-600" />
      </button>
    </>
  ) : (
    <div className="flex items-center space-x-4">
      <input 
        type="number"
        className="w-48 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
        value={editValue}
        onChange={(e) => handleTargetChange(e.target.value)}
      />
      {editValue >= dailyRevenue * 360 && (
        <button onClick={handleTargetSubmit}>
          <CheckIcon className="h-5 w-5 text-green-500 hover:text-green-600" />
        </button>
      )}
      <button onClick={() => setIsEditingTarget(false)}>
        <XMarkIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
      </button>
    </div>
  )}
</div>
    {targetRevenue < dailyRevenue * 360 && (
  <span className="text-sm text-red-500">
    Target Revenue cannot be less than Projected Revenue (₹{formatMoney(dailyRevenue * 360)})
  </span>
)}
    
  </div>
</div>
 */}



{/* <div className="bg-white rounded-lg shadow-md p-6 mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-700">Revenue Progress</h2>
  <div className="relative pt-1">
    <div className="flex mb-2 items-center justify-between">
      <div>
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
          Progress to 8 Crores
        </span>
      </div>
      <div className="text-right">
        <span className="text-xs font-semibold inline-block text-indigo-600">
          {((revenue / REVENUE_TARGET) * 100).toFixed(1)}%
        </span>
      </div>
    </div>
    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
      <div 
        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
        style={{ width: `${(revenue / REVENUE_TARGET) * 100}%` }}
      ></div>
    </div>
    <div className="flex justify-between text-sm">
      <span>Current: ₹{formatMoney(revenue)}</span>
      <span>Target: ₹8 Crores</span>
    </div>
  </div>
</div> */}




{/* <div className="bg-white rounded-lg shadow-md p-6 mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-700">Revenue Target Timeline</h2>
  <div className="flex items-center justify-between">
    <div className="flex-1">
      <div className="text-sm text-gray-500">At Current Pace</div>
      <div className="text-3xl font-bold text-indigo-600">
        ₹{formatMoney(dailyRevenue)} <span className="text-base text-gray-500">per day</span>
      </div>
    </div>
    <div className="flex-1 text-center">
      <div className="text-sm text-gray-500">Expected to Reach</div>
      <div className="text-3xl font-bold text-green-600">₹8 Crores</div>
    </div>
    <div className="flex-1 text-right">
      <div className="text-sm text-gray-500">Target Date</div>
      <div className="text-3xl font-bold text-indigo-600">
      Target Date
      </div>
      <div className="text-sm text-gray-500">
        (X days remaining)
      </div>
    </div>
  </div>
</div> */}





        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales (Products)</h2>
            <div className="h-64">
              <Bar options={horizontalBarChartOptions} data={createHorizontalBarChartData(topProducts, 'Product Revenue')} />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales Contribution (Products)</h2>
            <div className="h-64">
              <Pie options={pieChartOptions} data={createPieChartData(topProducts)} />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales (Staff)</h2>
            <div className="h-64">
              <Bar options={horizontalBarChartOptions} data={createHorizontalBarChartData(topUsers, 'User Revenue')} />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales Contribution (Staff) </h2>
            <div className="h-64">
              <Pie options={pieChartOptions} data={createPieChartData(topUsers)} />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales (Customers)</h2>
            <div className="h-64">
              <Bar options={horizontalBarChartOptions} data={createHorizontalBarChartData(topContacts, 'Customer Revenue')} />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Sales Contribution (Customers)</h2>
            <div className="h-64">
              <Pie options={pieChartOptions} data={createPieChartData(topContacts)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

