import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import ProductForm from './ProductForm';
import { useBusinessContext } from '../context/BusinessContext';

const Products = () => {
  const { businessId } = useBusinessContext();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
const [productToDelete, setProductToDelete] = useState(null);
const [showSuccessToast, setShowSuccessToast] = useState(false);
const [successMessage, setSuccessMessage] = useState('');



  useEffect(() => {
    fetchProducts();
  }, []);


  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/products?filters[business][id][$eq]=${businessId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Full products response:', response);
      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    setEditingProduct(null);
    setIsFormOpen(true);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setIsFormOpen(true);
  };

  const handleSaveProduct = (savedProduct) => {
    if (editingProduct) {
      setProducts(products.map(p => p.id === savedProduct.id ? savedProduct : p));
      setSuccessMessage('Product updated successfully');
    } else {
      setProducts([...products, savedProduct]);
      setSuccessMessage('New product added successfully');
    }
    setIsFormOpen(false);
    setEditingProduct(null);
    setShowSuccessToast(true);
  setTimeout(() => setShowSuccessToast(false), 3000);
  };

  const handleDeleteProduct = (productId) => {
  setProductToDelete(productId);
  setShowConfirmModal(true);
};



const performDelete = async () => {
  try {
    await axios.delete(`${config.API_URL}/api/products/${productToDelete}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    setProducts(products.filter(product => product.id !== productToDelete));
    setShowConfirmModal(false);
    setSuccessMessage('Product deleted successfully');
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
  } catch (error) {
    console.error('Error deleting product:', error);
  }
};





  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }


  return (

    




    <div className="container mx-auto px-4 py-8">

        <div className="flex justify-between items-center mb-6">
        <h1 className="text-indigo-600 text-3xl font-bold mb-6">Products</h1>
        <button
          onClick={handleAddProduct}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Product
        </button>
      </div>
      
      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-center">Price</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">Product Code</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {products.map((product) => (
              <tr key={product.id} className="border-b border-gray-200 hover:bg-gray-100">

                <td className="py-4 px-6 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{product.attributes.name.slice(0, 20)}</span>
                  </div>
                </td>

                <td className="py-3 px-6 text-center">
                  <span className="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">
                    {product.attributes.price}
                  </span>
                </td>

                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <span>{product.attributes.description.slice(0, 50)}...</span>
                  </div>
                </td>

                <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="flex items-center"> 
                        <span>{product.attributes.productcode.slice(0, 10)}</span>
                    </div>
                </td>

                
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <button onClick={() => handleEditProduct(product)}  className="w-4 mr-2 transform hover:text-indigo-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </button>
                    <button onClick={() => handleDeleteProduct(product.id)} className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ProductForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSave={handleSaveProduct}
        editingProduct={editingProduct}
      />

      </div>


      {showConfirmModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
        <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this product? This action cannot be undone.
          </p>
        </div>
        <div className="items-center px-4 py-3">
          <button
            id="ok-btn"
            className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={performDelete}
          >
            Delete Product
          </button>
          <button
            id="cancel-btn"
            className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}



{showSuccessToast && (
  <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
    {successMessage}
  </div>
)}


    </div>



  );
};

export default Products;
